import { ConfirmDialog } from 'primereact/confirmdialog';
import styled from 'styled-components';

/**
 * @deprecated Use confirmDialogV2 instead
 */
export const PConfirmDialogStyle = {
  ConfirmDialog: styled(ConfirmDialog)`
    & > .p-confirm-dialog {
      background-color: red !important;
    }
  `,
};
