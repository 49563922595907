import { ConfirmDialog, ConfirmDialogProps } from 'primereact/confirmdialog';
import React from 'react';
import { PConfirmDialogStyle as S } from './PConfirmDialog.style';

/**
 * @deprecated Use confirmDialogV2 instead
 */
export const PConfirmDialog = React.forwardRef<ConfirmDialog, ConfirmDialogProps>((props, ref) => {
  return <S.ConfirmDialog key="confirm-dialog" {...props} ref={ref} />;
});
