export namespace ChatModalTypes {
  export type ChatMessage = {
    id: number;
    animate?: boolean;
    replyToMessageId?: number | null;
    userId?: number | null;
    message: string;
    createdAt?: string;
    'chatMessagesResponses@count'?: number | null;
    user: {
      firstName: string;
      lastName: string;
    };
    waitingOnReply?: boolean;
    chatMessageFeedbacks: { feedbackPositive: boolean }[];
    adPersona?: {
      name: string;
      id: number;
      avatarPath?: string;
    };
  };
  export type Weaviate = {
    status: 'created' | 'processing' | 'completed' | 'failed' | 'checked'; // checked is FE only status
  };
  export type Chat = {
    createdAt: string;
    creator: { firstName: string; lastName: string; id: number };
    id: number;
    project: { id: string; name: string };
    question: { id: string; title: string };
    syncStatus?: Weaviate;
    updatedAt: string;
  };
  export class FormFields {
    public projectId: string | null = null;
    public questionId: string | null = null;
  }
}
