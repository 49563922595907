import React from 'react';
import { BoxStyle as S } from './Box.style';
import { borders, colourPalette, padding, borderRadius, shadow } from 'styles/styles';

type BoxProps = {
  padding?: keyof typeof padding | (keyof typeof padding)[];
  borderWidth?: keyof typeof borders | (keyof typeof borders)[];
  borderColor?: keyof typeof colourPalette;
  backgroundColor?: keyof typeof colourPalette;
  backgroundColorAlpha?: number;
  radius?: keyof typeof borderRadius;
  shadow?: keyof typeof shadow;
  stretch?: boolean;
  fit?: 'content' | 'space';
  noAutoMinSize?:
    | 'width'
    | 'height'
    | 'both' /** @see https://www.bigbinary.com/blog/understanding-the-automatic-minimum-size-of-flex-items */;
  testId?: string;
  children?: React.ReactNode;
  className?: string;
};

export const Box: React.FC<BoxProps> = ({ children, className, testId, ...props }) => {
  return (
    <S.Box $props={props} className={className} data-testid={testId}>
      {children}
    </S.Box>
  );
};
